// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-articles-js": () => import("./../../../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-dev-log-js": () => import("./../../../src/pages/dev-log.js" /* webpackChunkName: "component---src-pages-dev-log-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-blog-article-js": () => import("./../../../src/templates/blog-article.js" /* webpackChunkName: "component---src-templates-blog-article-js" */),
  "component---src-templates-live-article-js": () => import("./../../../src/templates/live-article.js" /* webpackChunkName: "component---src-templates-live-article-js" */)
}

